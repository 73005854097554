<template>
<div class="administrationlink-container">
    <vs-dialog blur prevent-close v-model="active">
        <template #header>
            <h4 class="not-margin">
                Crea account <b>link</b>
            </h4>
        </template>

        <div class="con-form" style="display: flex; flex-direction: column; gap: 30px;">
            <vs-input :disabled="loading" v-model="url" label="URL Destinazione" placeholder="Eg. https://tourial.it" block />
            <vs-input :disabled="loading" v-model="description" label="Descrizione" placeholder="Eg. Campagna Facebook" block />
            <vs-input disabled v-model="link" label="Link" block />
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="createLink" :loading="loading">
                    {{ $t('common.createNew') }}
                </vs-button>

            </div>
        </template>
    </vs-dialog>
</div>
</template>

<script>
import QRCode from 'qrcode'

import {
    apiCall
} from '../../utils/ApiMiddleware';
export default ({
    data() {
        return {
            loading: false,
            url: 'https://tourial.it',
            description: '',
            active: true,
            link: '',
        }
    },
    props: {
        user: {
            type: Object,
            required: false
        }
    },

    mounted() {

    },

    methods: {

        downloadURI(uri, name) {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            document.deleteElement(link);
        },

        async createLink() {
            this.loading = true;
            const response = await apiCall('POST', '/backoffice/links', {
                url: this.url,
                description: this.description,
            });
            this.loading = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {

                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Link creato con successo.',
                    color: 'success',
                    position: 'top-right'
                });
                this.url = '';
                this.description = '';
                this.link = response.data.url;
                QRCode.toDataURL(this.link)
                    .then(url => {
                        console.log(url)
                        this.downloadURI(url, 'qrcode-' + (new Date()).toISOString() + '.png')
                    })
                    .catch(err => {
                        console.error(err)
                    })
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile creare il link.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

    },

    watch: {
        active(n) {
            if (!n) {
                this.$router.go(-1);
            }
        }
    }
})
</script>

<style scoped>
.administrationlink-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
